<template>
    <div class="app">
        <header>
			<a @click.prevent="$router.go(-1)" href="" class="back"><i class="fad fa-angle-left"></i></a>

			<p class="title">ORD-{{ data.id }}</p>
		</header>

        <main>
            <img :src="data.image_url" alt="img">
        </main>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            token: localStorage.getItem('token'),
            id: this.$route.params.id,
            data: ''
        }
    },
    methods: {
        getData() {
            axios.get(`order/${this.id}?token=${this.token}`)
                .then(res => {
                    this.data = res.data;
                })
                .catch(err => {
                    console.log(err.response.data);
                }); 
        }
    },
    created() {
        this.getData();
    },
}
</script>

<style scoped>
    
img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

main {
    width: 100%;
    height: fit-content;
}

</style>